import {
  faLock,
  faMicrophone,
  faShareSquare,
  faSpinner,
  faBook,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare as farCheckSquare,
  faFilePdf as farFilePdf,
} from '@fortawesome/free-regular-svg-icons';

import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { blue, red } from '../../styles';

const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
    font-weight: 600;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const TopBanner = styled.div`
  background-color: #f7f7f7;
  padding: 15px 0px;
`;

const BannerWapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const H1 = styled.h1`
  color: #00a88e;
  font-size: 23px;
  line-height: 38px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
`;

const H2 = styled.h2`
  margin-top: 30px;
  border-bottom: solid 2px ${(props) => props.theme.colors.materialsTitleBorder};
  padding-bottom: 7px;
  color: ${(props) => props.theme.colors.materialsTitle};
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
`;

const StandardH3 = styled.h3`
  margin-top: 35px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 15px;
  font-weight: 500;
`;

const StandardH4 = styled.h4`
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  margin: ${(props) => (props.first ? '10px 0px 15px' : '25px 0px 15px')};
  color: #333333;
`;

const ParagraphText = styled.p`
  text-align: start;
`;

const HeaderIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.colors.materialsIcon};
  margin-right: 5px;
`;

const LinkInText = styled.a`
  color: #4a4a4a;
  font-weight: bold;
  :hover {
    color: ${(props) => props.theme.colors.materialsLinkHover};
    cursor: pointer;
  }
`;

const InlineIcon = styled(FontAwesomeIcon)`
  margin-left: 6px;
`;

const UlStyled = styled.ul`
  font-size: 14px;
  line-height: 1.7;
  font-family: 'Open Sans' sans-serif;
  list-style-type: none;
  text-align: left;
  padding-left: 0px;
`;

const TopLevelLi = styled.li`
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
`;

const NoBullets = styled.ul`
  list-style-type: none;
`;

const courses2023 = [
  {
    title: 'CE 1: Preclinical Considerations for Gene Therapies',
    coursepdf: '/books/CE1-Gene_Therapy_Coursebook.pdf',
    code: 'CE 1',
    certificate: '',
    survey: '',
  },
  {
    title:
      'CE 2: Demystifying the Realm of Pink and Blue: Toxicologic Histopathology 101',
    code: 'CE 2',
    coursepdf: '/books/CE2-Histopathology_101.pdf',
    certificate: '',
    survey: '',
  },
  {
    title:
      'CE 3: Fundamentals of Absorption, Distribution, Metabolism, and Excretion (ADME), Pharmacokinetics and Toxicokinetics for Toxicologists',
    code: 'CE 3',
    coursepdf: '/books/CE3-ADME_Coursebook.pdf',
    certificate: '',
    survey: '',
  },
  {
    title:
      'CE 4: Juvenile Toxicology Three Years After ICH S11: Where Are We Now?',
    code: 'CE 4',
    coursepdf: '/books/CE4-Juvenile_Toxicology_Coursebook.pdf',
    certificate: '',
    survey: '',
  },
  {
    title:
      'CE 5: Basic Considerations for Cell Therapy Toxicology from an Industry and Regulatory Perspective',
    code: 'CE 5',
    coursepdf: '/books/CE5-Cell_Therapy_Coursebook.pdf',
    certificate: '',
    survey: '',
  },
  {
    title:
      'CE 6: From Anticipated Immunomodulation to Safety Concern: Use of Immune Biomarkers in Preclinical Studies',
    code: 'CE 6',
    coursepdf: '/books/CE6-Biomarkers_Coursebook.pdf',
    certificate: '',
    survey: '',
  },
  {
    title:
      'CE 7: Medical Device Biocompatibility and Toxicological Risk Assessments: Processes, Challenges, and Solutions',
    coursepdf: '/books/CE7-Medical_Devices_Coursebook.pdf',
    code: 'CE 7',
    certificate: '',
    survey: '',
  },
  {
    title:
      'CE 8: Drug Development in the 21st Century: Improving Safety Evaluation and Risk Characterization with <i>In Vitro</i> Systems',
    coursepdf: '/books/CE8-Drug_Development_Coursebook.pdf',
    code: 'CE 8',
    certificate: '',
    survey: '',
  },
];

const courses2024 = [
  {
    title:
      'CE 1: Regulatory Toxicology Case Studies: An Interactive Session Involving Industry, CRO, Consultant and Regulatory Perspectives',
    lineSplit: {
      line1:
        'CE 1: Regulatory Toxicology Case Studies: An Interactive Session Involving Industry,',
      line2: 'CRO, Consultant and Regulatory Perspectives',
    },
    coursepdf: '/books/CE 1.pdf',
    code: 'CE 1',
    survey: 'https://www.surveymonkey.com/r/AM24_CE1',
  },
  {
    title:
      'CE 2: Fundamentals of Nonclinical Toxicology Study Pathology Reports, Peer Reviews, and Working Groups for Toxicologists and Regulators',
    lineSplit: {
      line1:
        'CE 2: Fundamentals of Nonclinical Toxicology Study Pathology Reports, Peer Reviews,',
      line2: 'and Working Groups for Toxicologists and Regulators',
    },
    code: 'CE 2',
    coursepdf: '/books/CE 2.pdf',
    survey: 'https://www.surveymonkey.com/r/AM24_CE2',
  },
  {
    title:
      'CE 3: The Use of Novel Tools in Nonclinical Safety to Advance Drug Discovery and Early Development',
    lineSplit: {
      line1:
        'CE 3: The Use of Novel Tools in Nonclinical Safety to Advance Drug Discovery',
      line2: 'and Early Development',
    },
    code: 'CE 3',
    coursepdf: '/books/CE 3.pdf',
    survey: 'https://www.surveymonkey.com/r/AM24_CE3',
  },
  {
    title:
      'CE 4: Safety Considerations for the Evaluation of Lipid-Based Nanoparticles',
    code: 'CE 4',
    coursepdf: '/books/CE 4.pdf',
    survey: 'https://www.surveymonkey.com/r/AM24_CE4',
  },
  {
    title:
      'CE 5: Translational Challenges from Nonclinical to Clinical Program: Case Study Examples',
    code: 'CE 5',
    coursepdf: '/books/CE 5.pdf',
    survey: 'https://www.surveymonkey.com/r/AM24_CE5',
  },
  {
    title: 'CE 6: Toxicologic Neuropathology of Novel Therapeutics',
    code: 'CE 6',
    coursepdf: '/books/CE 6.pdf',
    survey: 'https://www.surveymonkey.com/r/AM24_CE6',
  },
  {
    title:
      'CE 7: Nonclinical Immunotoxicity Assessment: State of the Science and Future Directions',
    coursepdf: '/books/CE 7.pdf',
    code: 'CE 7',
    survey: 'https://www.surveymonkey.com/r/AM24_CE7',
  },
  {
    title: 'CE 8: Toxicological Assessment of Pharmaceutical Impurities',
    coursepdf: '/books/CE 8.pdf',
    code: 'CE 8',
    survey: 'https://www.surveymonkey.com/r/AM24_CE8',
  },
];

function download(code) {
  fetch(`/api/getCoursePDF?course=${code}`).then((response) => {
    return response.blob().then((b) => {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(b);
      a.setAttribute('download', `${code}.pdf`);
      a.click();
    });
  });
}

const Materials2023 = () => (
  <Fragment>
    <div className="row">
      <LeftColumn className="col-sm-9">
        <img
          alt="people doing business"
          src="https://www.actox.org/am/am2023/images/banners/Meeting-Materials.jpg"
          className="img-fluid"
        />
        <div>
          <H2>2023 Meeting Materials and Resources</H2>
        </div>
        <StandardH3>
          <HeaderIcon icon={farFilePdf} />
          <span>Meeting Publications</span>
        </StandardH3>
        <p>
          <LinkInText href="/books/ACT-AM23-Abstract-Book.pdf" target="_blank">
            Abstract Book
          </LinkInText>
          <InlineIcon icon={farFilePdf} />
        </p>
        <p>
          <LinkInText href="" target="_blank">
            Attendee List
          </LinkInText>
          <InlineIcon icon={faLock} />
        </p>
        <p>
          <LinkInText
            href="https://www.actox.org/am/am2023/ce-books.asp"
            target="_blank"
          >
            Continuing Education (CE) Books&mdash;CE Registrants Only
          </LinkInText>
          <InlineIcon icon={faLock} />
        </p>
        <p>
          <LinkInText
            href="https://www.actox.org/am/am2023/posters.asp"
            target="_blank"
          >
            ePosters
          </LinkInText>
          <InlineIcon icon={faLock} />
        </p>
        <p>
          <LinkInText href="/books/ACT-AM23-pocketbook.pdf" target="_blank">
            Pocket Program
          </LinkInText>
          <InlineIcon icon={farFilePdf} />
        </p>
        <StandardH3>
          <HeaderIcon icon={faMicrophone} />
          <span>Plenary Speakers</span>
        </StandardH3>
        <StandardH4 first>
          <em>Monday, November 13, 2023</em>
        </StandardH4>
        <ParagraphText>
          <strong>
            Plenary 1: Gain of Function and Pandemic Therapies: A Double-Edged
            Sword
          </strong>
          <br />
          <br />
          <strong>Plenary Speaker: </strong>
          <LinkInText
            href="https://www.actox.org/am/am2024/plenary.asp"
            target="_blank"
          >
            Michael V. Callahan, MD, DTM&H, MSPH
          </LinkInText>
        </ParagraphText>
        <StandardH4>
          <em>Wednesday, November 15, 2023</em>
        </StandardH4>
        <ParagraphText>
          <strong>
            Plenary 2: Can Phage Therapy Safely Address the Looming Antibiotic
            Resistance Crisis?
          </strong>
          <br />
          <br />
          <strong>Plenary Speaker: </strong>
          <LinkInText
            href="https://www.actox.org/am/am2024/plenary.asp"
            target="_blank"
          >
            Paul E. Turner, PhD
          </LinkInText>
        </ParagraphText>

        <StandardH3>
          <HeaderIcon icon={farCheckSquare} />
          <span>Post Annual Meeting Survey</span>
        </StandardH3>
        <ParagraphText>
          A link to the online post-meeting survey will be emailed to all
          attendees shortly after the meeting. We hope you will provide
          feedback, so we are able to evaluate and accommodate your suggestions
          at future ACT meetings.
        </ParagraphText>
        <ParagraphText>
          <LinkInText
            href="https://www.surveymonkey.com/r/actam2023"
            target="_blank"
          >
            Post Annual Meeting Survey
          </LinkInText>
        </ParagraphText>
        <StandardH3>
          <HeaderIcon icon={faShareSquare} />
          <span> 2024 Session Proposals</span>
        </StandardH3>
        <ParagraphText>
          Proposals for the 2024 meeting may be submitted using the&nbsp;
          <LinkInText
            href="https://www.abstractscorecard.com/cfp/submit/login.asp?EventKey=CDVZZTNM"
            target="_blank"
          >
            online Session Proposals System
          </LinkInText>
          . <strong>The deadline is December 15, 2023.</strong> Please note that
          at least one chair of a session must be member of ACT. Questions may
          be directed to&nbsp;
          <LinkInText href="mailto:jehler@actox.org">
            Jordan Ballance Ehler
          </LinkInText>
          .
        </ParagraphText>
        <ParagraphText>
          <LinkInText
            href="https://www.abstractscorecard.com/cfp/submit/login.asp?EventKey=CDVZZTNM"
            target="_blank"
          >
            2024 Online Session Proposal Submission System
          </LinkInText>
        </ParagraphText>
        <ParagraphText>
          The 45th Annual Meeting of the American College of Toxicology will be
          held November 17-20, 2024 at the JW Marriott Austin, Austin, Texas.
        </ParagraphText>
      </LeftColumn>
    </div>
  </Fragment>
);
const Materials2024 = ({ coursesWithAccess, userName }) => (
  <Fragment>
    <div className="row">
      <LeftColumn className="col-sm-9">
        <img
          alt="people doing business"
          src="https://www.actox.org/am/am2023/images/banners/Meeting-Materials.jpg"
          className="img-fluid"
        />
        <div>
          <H2>2024 Meeting Materials and Resources</H2>
        </div>
        <StandardH3>
          <HeaderIcon icon={farFilePdf} />
          <span>Meeting Publications</span>
        </StandardH3>
        <p>
          <LinkInText href="/books/ACT-AM23-Abstract-Book.pdf" target="_blank">
            Abstract Book
          </LinkInText>
          <InlineIcon icon={farFilePdf} />
        </p>
        <p>
          <LinkInText href="/api/generatePDF" target="_blank">
            Attendee List
          </LinkInText>
          <InlineIcon icon={faLock} />
        </p>
        <p>
          <LinkInText href="/books/ACT-AM24-pocketbook-Materials-Page.pdf" target="_blank">
            Pocket Program
          </LinkInText>
          <InlineIcon icon={farFilePdf} />
        </p>
        <StandardH3>
          <HeaderIcon icon={faMicrophone} />
          <span>Plenary Speakers</span>
        </StandardH3>
        <StandardH4 first>
          <em>Monday, November 18, 2024</em>
        </StandardH4>
        <ParagraphText>
          <strong>
            Plenary 1: Generative AI for Toxicology and Drug Safety
          </strong>
          <br />
          <br />
          <strong>Plenary Speaker: </strong>
          <LinkInText
            href="https://www.actox.org/am/am2024/plenary.asp"
            target="_blank"
          >
            Weida Tong, PhD - NCTR, US FDA
          </LinkInText>
        </ParagraphText>
        <StandardH4>
          <em>Wednesday, November 20, 2024</em>
        </StandardH4>
        <ParagraphText>
          <strong>
            Plenary 2: Medicinal Evolution of Natural Hormones to Transform the
            Drug Treatment of Obesity
          </strong>
          <br />
          <br />
          <strong>Plenary Speaker: </strong>
          <LinkInText
            href="https://www.actox.org/am/am2024/plenary.asp"
            target="_blank"
          >
            Richard DiMarchi, PhD - Gill Chair in Biomolecular Science at
            Indiana University
          </LinkInText>
        </ParagraphText>
        <StandardH3>
          <HeaderIcon icon={faBook} />
          <span>Continuing Education (CE) Course Materials</span>
        </StandardH3>
        <ParagraphText>
          CE AM and PM course registrants may switch to a different AM and PM
          course without paying an additional course registration fee until
          November 7. After November 7 switching courses will be regarded as a
          separate registration, and an additional course fee will be required.
        </ParagraphText>
        <ParagraphText>
          All CE course registrants have access to their course booklet(s). You
          may access the course materials below where you will be able to view,
          download, and print your materials. Each coursebook is only accessible
          to those currently registered for the course. If you would like to add
          a CE to your registration, please contact{' '}
          <LinkInText href="mailto:acthq@actox.org" target="_blank">
            ACT Headquarters
          </LinkInText>
          .
        </ParagraphText>
        {courses2024.map((course) => {
          const certificateUrl = course.lineSplit
            ? `/api/generateCertificate?courseLine1=${course.lineSplit.line1}&courseLine2=${course.lineSplit.line2}&name=${userName}`
            : `/api/generateCertificate?courseLine1=${course.title}&name=${userName}`;
          return (
            <ParagraphText key={course.code}>
              <strong>
                {course.title}
                <br />
              </strong>
              {coursesWithAccess.includes(course.code) && (
                <>
                  <LinkInText onClick={() => download(course.code)}>
                    Printable Coursebook{' '}
                  </LinkInText>
                  <br />
                  <LinkInText href={certificateUrl} target="_blank">
                    {course.code} Certificate
                  </LinkInText>
                  <br />
                  <LinkInText href={course.survey} target="_blank">
                    Survey
                  </LinkInText>
                </>
              )}
            </ParagraphText>
          );
        })}
        <StandardH3>
          <HeaderIcon icon={farCheckSquare} />
          <span>Post Annual Meeting Survey</span>
        </StandardH3>
        <ParagraphText>
          A link to the online post-meeting survey will be emailed to all
          attendees shortly after the meeting. We hope you will provide
          feedback, so we are able to evaluate and accommodate your suggestions
          at future ACT meetings.
        </ParagraphText>
        <ParagraphText>
          <LinkInText
            href="https://www.surveymonkey.com/r/ACT45AM"
            target="_blank"
          >
            Post Annual Meeting Survey
          </LinkInText>
        </ParagraphText>
        <StandardH3>
          <HeaderIcon icon={faShareSquare} />
          <span> 2025 Session Proposals</span>
        </StandardH3>
        <ParagraphText>
          Proposals for the 2025 meeting may be submitted using the&nbsp;
          <LinkInText
            href="https://www.abstractscorecard.com/cfp/submit/login.asp?EventKey=CDVZZTNM"
            target="_blank"
          >
            online Session Proposals System
          </LinkInText>
          . <strong>The deadline is December 15, 2024.</strong> For your
          proposal to be considered, at least one chair of a session must be an
          active ACT Member. Questions may be directed to&nbsp;
          <LinkInText href="mailto:jehler@actox.org">
            Jordan Ballance Ehler
          </LinkInText>
          .
        </ParagraphText>
        <ParagraphText>
          <LinkInText
            href="https://www.abstractscorecard.com/cfp/submit/login.asp?EventKey=CDVZZTNM"
            target="_blank"
          >
            2025 Online Session Proposal Submission System
          </LinkInText>
        </ParagraphText>
        <ParagraphText>
          The 46th Annual Meeting of the American College of Toxicology will be
          held November 16-19, 2025 at the JW Marriott Desert Ridge in Phoenix,
          Arizona.
        </ParagraphText>
      </LeftColumn>
    </div>
  </Fragment>
);

const Materials = () => {
  const { year } = useParams();
  const [hasAccess, setHasAccess] = useState(false);
  const [userName, setUserNmae] = useState('');
  const [coursesWithAccess, setCoursesWithAccess] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const result = await fetch(`/api/isMember`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ year }),
        });
        if (result.status === 401) {
          navigate('/login?redirect=' + encodeURIComponent(pathname));
        } else if (result.status === 403) {
          setError('You do not have access to this page');
        } else if (result.status === 200) {
          const response = await result.json();
          setUserNmae(response.name);
          setHasAccess(response.isMember);
          setError(null);
        } else {
          setError('An unexpected error occurred');
        }
      } catch (e) {
        setError('An unexpected error occurred');
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (hasAccess && year === '2024')
      (async () => {
        try {
          const result = await fetch(`/api/getBookAccess`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          if (result.status === 200) {
            const courses = await result.json();
            setCoursesWithAccess(courses.codes);
          }
        } catch (e) {
          console.log(e);
        }
      })();
  }, [hasAccess]);
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const result = await fetch(`/api/getCEs2023`, {
  //         method: 'GET',
  //         mode: 'cors',
  //         cache: 'no-cache',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //       });
  //       if ( result.status === 401 ) {
  //         navigate('/login?redirect=' + encodeURIComponent(pathname));
  //       } else if ( result.status === 403 ) {
  //         setError("You do not have access to this page");
  //       } else if ( result.status === 200 ) {
  //         const { ces } = await result.json();
  //         setError(null);
  //         setCes( ces );
  //       } else {
  //         setError("An unexpected error occurred");
  //       }
  //     } catch (e) {
  //       setError("An unexpected error occurred");
  //     }
  //     setLoading(false);
  //   })();
  // },[])

  const MaterialsByYear = () => {
    switch (year) {
      case '2023':
        return <Materials2023 />;
      case '2024':
        return (
          <Materials2024
            coursesWithAccess={coursesWithAccess}
            userName={userName}
          />
        );
      default:
        return <Materials2023 />;
    }
  };

  return (
    <div>
      <div className="container">
        {loading ? (
          <Panel>
            <Loading>
              <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
            </Loading>
          </Panel>
        ) : error ? (
          <Panel>
            <div>
              <br />
              <br />
              <p>{error}</p>
              <br />
              <br />
            </div>
          </Panel>
        ) : (
          <MaterialsByYear />
        )}
      </div>
    </div>
  );
};

export default Materials;
